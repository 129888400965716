<template>
  <div>
    <!--导航栏--->
    <div> <van-nav-bar title="新建收货地址" left-arrow @click-left="$router.back(-1)" /></div>
    <!--导航栏--->
    <div>
      <van-address-edit :area-list="areaList" show-postal show-set-default
        show-search-result
        :search-result="searchResult"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
        @change-detail="onChangeDetail"
        delete-button-text
       />
    </div>
    <!-- 底部 -->
    <!-- <div @click="$router.push('/')">
      <van-goods-action>
        <van-goods-action-button type="warning" text="保存" />
      </van-goods-action>
    </div> -->
    <!-- 底部 -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { areaList } from '@vant/area-data';

export default {
 data() {
    return {
      areaList,
      Toast:null,
      searchResult: [],
    };
  },
  methods: {
    onSave() {
      Toast('save');
    },
    onDelete() {
       Toast('delete');
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: '黄龙万科中心',
            address: '杭州市西湖区',
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
  },
    components: {
  }
};
</script>
<style lang="less" scoped>
 /deep/.van-button{
  background-color: #F52556 ;
}
</style>
